import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import styles from './email.module.css'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { BASE_URL } from '../../Apis/BaseUrl'
import axios from 'axios'
import Moment from 'react-moment'
import NewPagination from '../../Component/NewPagination/NewPagination'
import NotFound from '../../Component/NotFound/NotFound'
import ContactModal from './Modal/ContactModal'

const ContactPage = () => {
    const[data, setData] = useState([])
    const[page, setPage] = useState(1)
    const[pageInfo, setPageInfo] = useState({})
    const[limit, setLimit] = useState(10)
    const[search, setSearch] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const[ID, setId] = useState('')


    const closeModal = () => {
        setIsOpen(false)
    }
    const openModal = (id) => {
        setIsOpen(true)
    setId(id)
    }
    
    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;
    const getData = async () => {
     
        let register = `${BASE_URL}/contacts?limit=${limit}&page=${page}&search=${search}`
    
        // console.log(register)
        let res = await axios.get(register, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
        console.log(res.data.data)
        setData(res.data.data.docs)
        setPageInfo({ ...res.data.data, docs: null })
      }

      useEffect(() => {
        getData()
    }, [])

    const paginationProps = {
        setPage,
        pageInfo, setLimit
      }
    return (
        <React.Fragment>
            <Helmet>
                <title>Contacts</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* ----------Hero-Section----------- */}

            <div className={styles.hero_Section}>
                <ContainerSection />

                <div className={styles.product_review_section}>
                    <h6>Contacts</h6>

                </div>


                {/* -------------table_section-------------- */}
{data?.length > 0 ? 
<>
                <div className={styles.main_table}>
                    <table className="table">
                        <thead>
                            <tr>
                                {/* <th scope="col">Sno</th> */}
                                <th  className='px-5'>Name</th>
                                <th>Email ID</th>
                                <th>Phone</th>
                                <th>Message</th>
                                <th className='px-5'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                           {data?.map((item, index) => (
                             <tr>
                             {/* <td>{index+1}</td> */}
                             <td className='px-5'>{item.name}</td>
                             <td>{item.email}</td>
                             <td>{item.phone}</td>
                             <td style={{width:"300px"}}>{item.message}</td>
                             <td className='px-5'>
                                <button className={styles.btn1} onClick={()=>openModal(item._id)}>Reply</button>
                             </td>
                             {/* <td><Moment format="DD/MM/YYYY" utc>{item.createdAt}</Moment></td> */}
                         </tr>
                           ))}

                        </tbody>
                    </table>
                    </div>
                    <NewPagination {...paginationProps}/>
                </>

: <NotFound cls={styles.not_found}/> }
            </div>
            {isOpen && <ContactModal id={ID}  isOpen={isOpen} closeModal={closeModal} />}
        </React.Fragment>
    )
}

export default ContactPage