import React, { useEffect, useState } from 'react'
import Charts from 'react-apexcharts';
import { Helmet } from 'react-helmet';
import styles from './payment.module.css'
import { Col, Row } from 'react-bootstrap'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'
import Cookies from 'js-cookie';
import axios from 'axios';
import { BASE_URL } from '../../Apis/BaseUrl';
import NewPagination from '../../Component/NewPagination/NewPagination';
import Moment from 'react-moment';
import PaymentModal from './PayModal/PaymentModal';
import formatter from '../../Component/NumberFormatter/formatter';
import NotFound from '../../Component/NotFound/NotFound';
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css'
import moment from 'moment';
import { FormatNumber } from '../../Component/FormatNumber/FormatNumber';

const Payments = () => {
    const [show, setShow] = useState(false)
    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [pageInfo, setPageInfo] = useState({})
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [graphData, setGraphData] = useState({})
    const [bottomData, setBottomData] = useState({})
    const [value, onChange] = useState('');
    const [ID, setID] = useState('')

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;

    const getData = async () => {
        let dateValue = value ? moment(value).format('YYYY-MM-DD') : " "; // Convert to null if empty string

        // console.log(register)
        let res = await axios.get(`${BASE_URL}/payment?limit=${limit}&page=${page}&fromDate=${dateValue}&toDate=${dateValue}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token} `,
            },
        })
        console.log(res.data.data)
        setData(res.data.data?.docs)
        setPageInfo({ ...res.data.data, docs: null })
    }

    useEffect(() => {
        getData()
    }, [limit, page, value])
    const paginationProps = {
        setPage, pageInfo,setLimit
    }
    const closeModal = () => {
        setShow(false)
    }
    const openModal = (id) => {
        setShow(true)
        setID(id)
    }
    const getBottomTiles = async () => {

        let register = `${BASE_URL}/dashboard/bottom-tiles`
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        // console.log(res.data.data)

        setBottomData(res.data.data)
    }

    useEffect(() => {
        getBottomTiles()
    }, [])
    const getGraphData = async () => {

        let register = `${BASE_URL}/dashboard/graph`
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        // console.log(res.data.data)

        setGraphData(res.data.data)
    }

    useEffect(() => {
        getGraphData()
    }, [])
    return (
        <React.Fragment>
            <Helmet>
                <title>Product</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* ----------Hero-Section----------- */}

            <div className={styles.hero_Section}>
                {/* <ContainerSection /> */}

                {/* -------------Graph_section------------- */}


                {/* -------------last_section------------- */}

                <Row className={styles.main_container_box}>
                    <div className={styles.container_box}>
                        <Col md={7} sm={12}>
                            <div className={styles.graph}>
                                <div className='graph-section'>

                                    <Charts type="bar" width={'100%'}
                                        height={400}
                                        series={[
                                            {
                                                name: "Gross earning",
                                                data: graphData?.grossEarning?.map(value => parseFloat(value.toFixed(0))) || []

                                            },
                                            {
                                                name: "Net Earning",
                                                data: graphData?.netEarning?.map(value => parseFloat(value.toFixed(0))) || []
                                            }
                                        ]}

                                        options={{
                                            title: {
                                                text: `Revenue In Last ${graphData?.labels ? graphData.labels.length : '0'} Month`, // Dynamic text

                                                style: { fontSize: '15.14px', fontWeight: '600', paddingTop: '20px' }
                                            },
                                            colors: ['#AE0000', '#000000'],

                                            xaxis: {
                                                categories: graphData?.labels || []
                                            },
                                            yaxis: {
                                                labels: {
                                                    formatter: function (val) {
                                                        return FormatNumber(val) // Add dollar symbol to y-axis labels
                                                    }
                                                }
                                            },
                                            stroke: {
                                                show: true,
                                                width: 4,
                                                colors: ['transparent']
                                            },
                                            dataLabels: {
                                                enabled: false // Disable data labels
                                            },
                                            tooltip: {
                                                y: {
                                                    formatter: function (val) {
                                                        return FormatNumber(val) // Add dollar sign to the value
                                                    }
                                                }}

                                        }}>
                                    </Charts>
                                </div>

                            </div>
                        </Col>
                        {/* <Col md={5} sm={12}>
                            <Row>
                                <Col md={6} sm={6}>
                                    <div className={styles.total_section}>
                                        <p>Total <br /> Sales this week</p>

                                        <h6>{formatter.format(bottomData?.weekSales)}</h6>

                                    </div>
                                </Col>
                                <Col md={6} sm={6}>
                                    <div className={styles.small_box}>
                                        <p>Total <br /> Order</p>
                                        <h6>{bottomData?.totalOrders}</h6>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} sm={6}>
                                    <div className={`${styles.sm_box} ${styles.small_box}`}>
                                        <p>Total <br /> Product category  </p>
                                        <h6>{bottomData?.totalCategories}</h6>
                                    </div>
                                </Col>
                                <Col md={6} sm={6}>
                                    <div className={`${styles.sm_box} ${styles.small_box}`}>
                                        <p>Total <br /> Product brand</p>
                                        <h6>{bottomData?.totalBrands}</h6>
                                    </div>
                                </Col>
                            </Row>
                        </Col> */}
                         <Col md={5} sm={12}>
                            <Row>
                                <Col md={6} sm={6}>
                                    <div className={styles.total_section}>
                                        <p>Today Sales</p>

                                        <h6>{FormatNumber(bottomData?.todaySales || 0)}</h6>

                                    </div>

                                </Col>
                                <Col md={6} sm={6}>
                                    <div className={styles.small_box}>
                                        <p>Weekly Sales</p>
                                        <h6>{FormatNumber(bottomData?.weeklySales || 0)}</h6>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} sm={6}>
                                    <div className={`${styles.sm_box} ${styles.small_box}`}>
                                        <p>Monthly Sales  </p>
                                        <h6>{FormatNumber(bottomData?.monthlySales || 0)}</h6>
                                    </div>
                                </Col>
                                <Col md={6} sm={6}>
                                    <div className={`${styles.sm_box} ${styles.small_box}`}>
                                        <p> Yearly Sales</p>
                                        <h6>{FormatNumber(bottomData?.yearlySales || 0)}</h6>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </Row>
                <div className={`${styles.date_area} mt-5`}>
                    <h5>Payment History</h5>
                    <DatePicker format="y/MM/dd" className={styles.choose_date} onChange={onChange} value={value} />
                </div>
                {/* -------------table_section-------------- */}
                {data?.length > 0 ?
                    <>
                        <div className={styles.main_table}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        {/* <th scope="col"><input type="radio" /></th> */}
                                        <th scope="col" className='px-5 text-start'> Order ID</th>
                                        {/* <th scope="col">Customer Name</th> */}
                                        <th scope="col">Transaction Id</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Payment Method</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((item) => (
                                        <tr>
                                            {/* <th scope="row"><input type="radio" /></th> */}
                                            <td className='px-5 text-start'> #{item.orderId}</td>
                                            {/* <td></td> */}
                                            <td className='text-capitalize'>{item?.paymentRefNumber ? item.paymentRefNumber : "_ _ _"}</td>
                                            <td>                        {FormatNumber(item?.amount || 0)}
                                            </td>
                                            <td className='text-capitalize'>{item?.paymentMethod}</td>
                                            <td><Moment format='YYYY/MM/DD'>{item.date}</Moment></td>
                                            <td >
                                                <div className={styles.image_section}>
                                                    <img src="/image/eye.png" alt="eye" onClick={() => openModal(item._id)} />
                                                    {/* <img src="/image/delete.png" alt="delete" /> */}
                                                </div>

                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                        <NewPagination {...paginationProps} />
                    </>
                    : <NotFound cls={styles.not_found} />}
            </div>
            {show && <PaymentModal func={getData} isOpen={show} id={ID} closeModal={closeModal} />}
        </React.Fragment>


    )
}

export default Payments