import React, { useEffect, useState } from 'react'
import styles from './blog.module.css'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'
import { Helmet } from 'react-helmet'
import Cookies from 'js-cookie'
import { BASE_URL } from '../../Apis/BaseUrl'
import axios from 'axios'
import Moment from 'react-moment'
import { toast } from 'react-toastify'
import ToasterUpdate from '../../Component/Toaster/ToasterUpdate'
import NewPagination from '../../Component/NewPagination/NewPagination'
import NotFound from '../../Component/NotFound/NotFound'


const Blogs = () => {
    const[data, setData] = useState([])
    const[page, setPage] = useState(1)
    const[pageInfo, setPageInfo] = useState({})
    const[limit, setLimit] = useState(10)
    const[search, setSearch] = useState('')
    const[status, setStatus] = useState('')
    const[loading, setLoading] = useState(false)
    const navigate = useNavigate()
    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;
    
    const getData = async () => {
     
        let register = `${BASE_URL}/blog?limit=${limit}&page=${page}&search=${search}&status=${status}`
    
        // console.log(register)
        let res = await axios.get(register, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
        console.log(res.data.data.docs)
        setData(res.data.data.docs)
        setPageInfo({ ...res.data.data, docs: null })
      }

      useEffect(() => {
        getData()
    }, [limit, page, search, status])

    const paginationProps = {
      setPage,
      pageInfo,setLimit
    }
const editNavigate = (id) => {
    console.log(id)
    navigate(`/blogs/editblogs/${id}`)
}
const handleDelete = async (id) => {
    console.log(id)
    const myToast = toast.loading('Please Wait...')
    setLoading(true)
    try {
      let register = `${BASE_URL}/blog/${id}`

      const response = await axios.delete(register,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        });
        console.log(response)
        toast.update(myToast, {
            render: response.data.message,
            type: 'success',
            isLoading: false,
            autoClose: 1500
        });
        
        getData()
    } catch (error) {
      toast.update(myToast, {
        render: error.message,
        type: 'error',
        isLoading: false,
        autoClose: 1500
      })
      console.error('Error deleting resource:', error);
    }
    finally{
        setLoading(false)
    }
  }

  const handleAccept = async (id) => {
    // e.preventDefault()
    // console.log(item)
    let register = `${BASE_URL}/blog/${id}/active`
    const myToast = toast.loading('Please Wait...')
    // console.log(register)
    try {

      let res = await axios.patch(register, {}, {
        // withCredentials : true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token} `,
        },
      })
      
      if (!res.data.success) {
        ToasterUpdate(myToast, res.data.message, "error")
        return
      }
      ToasterUpdate(myToast, res.data.message, "success")
   getData()
    } catch (error) {
      // console.log(error)
      ToasterUpdate(myToast, error.message, "error")
    }

  };
    return (
        <React.Fragment>
            <Helmet>
                <title>Blogs</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* -----------------hero-section---------------- */}

            <div className={styles.hero_Section}>
                {/* <ContainerSection /> */}

                <div className={styles.filter_section}>
                    <div className={styles.filter}>
                        <p>Filters : </p>
                        <p>Status </p>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio"
                                name="flexRadioDefault" id="flexRadioDefault2"
                                value=""
                                onChange={(e) => setStatus(e.target.value)}
                                checked={status === ''} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                All
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                value="active"
                                onChange={(e) => setStatus(e.target.value)} checked={status === 'active'} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Active
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                value="inactive"
                                onChange={(e) => setStatus(e.target.value)} checked={status === 'inactive'} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Inactive
                            </label>
                        </div>
                        </div>
                        </div>
                <div className={styles.mainSection}>

                    <div className='w-100'>
                        <Col className='d-flex justify-content-between align-items-center'>
                            <h6>Blogs</h6>
                            <div className={styles.btn_section}>
                           
                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} style={{
                        height: "40px",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #d9d9d9", outline: "none"
                    }} placeholder="Search..." required />

               
                        <button onClick={() => navigate('/blogs/addblogs')}>Add Blogs</button>
                    </div>
                        </Col>

                      
                    </div>
                </div>



                {/* -------------table_section-------------- */}

                  {data.length > 0 ? 
                  <>
                <div className={styles.main_table}>
                  
                    <table className="table">
                        <thead>
                            <tr>
                                {/* <th scope="col">#</th> */}
                                <th scope="col" className='px-5'>Title</th>
                                <th scope="col">Categories</th>
                                <th scope="col">Published</th>
                                <th scope="col">Active</th>
                                <th scope="col">Details</th>
                            </tr>
                        </thead>
                        <tbody>
                           {data?.map((item) => (
                             <tr>
                             {/* <td>1</td> */}
                             <td className='px-5 text-capitalize'> {item.title} </td>
                             <td className='text-capitalize'>{item.category}</td>
                             <td><Moment format="YYYY/MM/DD" utc>{item.date}</Moment></td>
                             <td>
                                 <div className="form-check form-switch">
                                     <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onChange={() => handleAccept(item._id, item)} checked={item.active} />
                                 </div>
                             </td>
                             <td>
                                 <div className={styles.image_section}>
                                     <img onClick={() => editNavigate(item._id)} src="/image/edit.png" alt="edit" />
                                     <img src="/image/delete.png" alt="delete" onClick={()=>handleDelete(item._id)}/>
                                 </div>
                             </td>
                         </tr>
                           ))}
                        </tbody>
                    </table> 
                    </div>
                    <NewPagination {...paginationProps} />
                    
                </>
                  : <NotFound cls= {styles.not_found}/>}
            </div>
        </React.Fragment>
    )
}

export default Blogs