import React, { useEffect, useState } from 'react'
import styles from './sales.module.css'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Cookies from 'js-cookie'
import { BASE_URL } from '../../Apis/BaseUrl'
import axios from 'axios'
import NewPagination from '../../Component/NewPagination/NewPagination'
import PDFFile from '../../Component/PDFViewer/PDFFile'
import { PDFDownloadLink } from '@react-pdf/renderer'
import NotFound from '../../Component/NotFound/NotFound'
import formatter from '../../Component/NumberFormatter/formatter'
import { FormatNumber } from '../../Component/FormatNumber/FormatNumber'
import Moment from 'react-moment'
const td1 = [
    {
        h5: "Today's Sale",
        p: '0'
    },
    {
        h5: 'Monthly Sale',
        p: '0',
    },
    {
        h5: 'Products',
        p: '0'
    },
    {
        h5: 'Wallet',
        p: '0'
    },
    {
        h5: 'Orders',
        p: '0'
    }
]


const DraftOrder = () => {
    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [pageInfo, setPageInfo] = useState({})
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')


    const navigate = useNavigate()
    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;

    const getData = async () => {

        let register = `${BASE_URL}/order-draft?limit=${limit}&page=${page}&search=${search}`


        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        console.log(res.data.data.docs)
        setData(res.data.data.docs)
        setPageInfo({ ...res.data.data, docs: null })
    }

    useEffect(() => {
        getData()
    }, [limit, page, search])

    const paginationProps = {
        setPage,
        pageInfo, setLimit
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Order Draft</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* -----------------hero-section---------------- */}

            <div className={styles.hero_Section}>
                {/* <ContainerSection data={td1} /> */}

                <div className={styles.mainSection}>
                    <div className={`w-100 d-flex justify-content-between ${styles.wdth}`}>

                        <h6 className='mb-0 '>Order Draft</h6>
                        <input type="text" className="form-control" placeholder="Type Order code & hit Enter" value={search} onChange={(e) => setSearch(e.target.value)} required />

                    </div>
                </div>



                {/* -------------table_section-------------- */}
                {data?.length > 0 ?
                    <>
                        <div className={styles.main_table}>
                            <table className="table">
                                <thead>
                                    <tr>

                                        <th className='px-5'>Draft ID</th>
                                        <th >Num. of Products</th>
                                        <th >Customer</th>
                                        <th >Date</th>
                                        <th >Sub Total</th>
                                        <th >Order Status</th>
                                        <th >Shipping Status</th>
                                        <th >Options</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((item, index) => (
                                        <tr>

                                            <td className='px-5'>#{item?.draftId}</td>

                                            <td className='text-capitalize'>{item?.qty}</td>
                                            <td className='text-capitalize'>{item?.customerId?.name}</td>

                                            <td ><Moment format='YYYY/MM/DD'>{item.date}</Moment></td>
                                            <td >${item.subtotal}</td>



                                            {item.orderStatus == "hold" && <td className='text-capitalize'><span className="badge bg-warning" >{item.orderStatus}</span></td>}
                                            {item.orderStatus == "cancelled" && <td className='text-capitalize'><span className="badge bg-danger" >{item.orderStatus}</span></td>}
                                            {item.orderStatus == "ordered" && <td className='text-capitalize'><span className="badge bg-success" >{item.orderStatus}</span></td>}
                                            <td>
                                                {item.shippingStatus == "pending" && <span className="badge bg-danger" >{item.shippingStatus}</span>}
                                                {item.shippingStatus == "calculated" && <span className="badge bg-warning" >{item.shippingStatus}</span>}
                                                {item.shippingStatus == "confirmed" && <span className="badge bg-success" >{item.shippingStatus}</span>}

                                            </td>
                                            <td>
                                                <div className={styles.image_section}>
                                                    <img onClick={() => navigate(`/sales/draft/draftdetail/${item._id}`)} src="/image/eye.png" alt="edit" />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}


                                </tbody>
                            </table>
                        </div>
                        <NewPagination {...paginationProps} />
                    </>
                    : <NotFound cls={styles.not_found} />}

            </div>
        </React.Fragment>
    )
}

export default DraftOrder