import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import styles from './ProductReport.module.css'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { BASE_URL } from '../../Apis/BaseUrl'
import axios from 'axios'
import { toast } from 'react-toastify'
import ToasterUpdate from '../../Component/Toaster/ToasterUpdate'
import DropInput from '../../Component/DropDownComp/DropInput'
import NewPagination from '../../Component/NewPagination/NewPagination'
import NotFound from '../../Component/NotFound/NotFound'
import FallProduct from '../../Component/FallbackImgae/FallProduct'
import ReviewReportModal from './ReviewReportModal/ReviewReportModal'
import Moment from 'react-moment'
import { RiCloseCircleLine } from 'react-icons/ri'
import { FaRegCheckCircle } from 'react-icons/fa'
import WithDrawalModal from '../../Component/DeleteModal/WithdrawalModal'

const ProductReport = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState([])
  const [data2, setData2] = useState([])
  const [page, setPage] = useState(1)
  const [pageInfo, setPageInfo] = useState({})
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [search1, setSearch1] = useState('')
  const [loading, setLoading] = useState(false)
  const [ID, setID] = useState('')
  const [itemData, setItemData] = useState({})
  const [productSearch, setProductSearch] = useState('')
  const [modalVisible, setModalVisible] = useState(false);
  const[IDapprove, setIdapprove] = useState('')
    const [modalDisapprove, setModalDisapprove] = useState(false);
  const[IDreject, setIdReject] = useState('')

  const openApproveModal = (id) => {
    // console.log(id)
    setIdapprove(id)
    setModalVisible(true);
  };

  const closeDisapproveModal = () => {
    setModalDisapprove(false);
  };
  const openDisapprove= (id) => {
    // console.log(id)
    setIdReject(id)
    setModalDisapprove(true);
  };

  const closeApproveModal = () => {
    setModalVisible(false);
  };

  const navigate = useNavigate()
  let profileTokenJson = Cookies.get("v&b_token");
  let profileToken = JSON.parse(profileTokenJson);
  // console.log(profileToken.token)
  let token = profileToken.token;

  const getData = async () => {
    // console.log(register)
    let res = await axios.get(`${BASE_URL}/reviewsReport?limit=${limit}&page=${page}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token} `,
      },
    })
    console.log(res.data.data)
    setData(res.data.data?.docs)
    setPageInfo({ ...res.data.data, docs: null })
  }

  useEffect(() => {
    getData()
  }, [limit, page, productSearch, search])



  const handleAccept = async (id) => {
    // e.preventDefault()
    console.log(id)
    let register = `${BASE_URL}/reviewsReport/${id}/status`
    const myToast = toast.loading('Please Wait...')
    // console.log(register)
    try {

        let res = await axios.patch(register, {status: "resolved"}, {
            // withCredentials : true,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token} `,
            },
        })

        if (!res.data.success) {
            ToasterUpdate(myToast, res.data.message, "error")
        }
        ToasterUpdate(myToast, res.data.message, "success")
        getData()
    } catch (error) {
        // console.log(error)
        ToasterUpdate(myToast, error.message, "error")
    }

};
const handleReject = async (id) => {
    // e.preventDefault()
    console.log(id)
    let register = `${BASE_URL}/reviewsReport/${id}/status`
    const myToast = toast.loading('Please Wait...')
    // console.log(register)
    try {

        let res = await axios.patch(register, {status : "reject"}, {
            // withCredentials : true,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token} `,
            },
        })

        if (!res.data.success) {
            ToasterUpdate(myToast, res.data.message, "error")
        }
        ToasterUpdate(myToast, res.data.message, "success")
        getData()
    } catch (error) {
        // console.log(error)
        ToasterUpdate(myToast, error.message, "error")
    }

};

const handleDelete = async (id) => {
  console.log(id)
  const myToast = toast.loading('Please Wait...')
  setLoading(true)
  try {
    let register = `${BASE_URL}/review/${id}/remove`

    const response = await axios.delete(register,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
    console.log(response)
    toast.update(myToast, {
      render: response.data.message,
      type: 'success',
      isLoading: false,
      autoClose: 1500
    });

    getData()
  } catch (error) {
    toast.update(myToast, {
      render: error.message,
      type: 'error',
      isLoading: false,
      autoClose: 1500
    })
    console.error('Error deleting resource:', error);
  }
  finally {
    setLoading(false)
  }
}

  const closeModal = (id) => {
    setIsOpen(false)
  }
  const openModal = (id, item) => {
    setIsOpen(true)
    setID(id)
    setItemData(item)
  }

  const paginationProps = {
    setPage, pageInfo,setLimit
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>Product Review Reports</title>
      </Helmet>
      <TopNavbar />
      <NavbarSection />

      {/* ----------Hero-Section----------- */}

      <div className={styles.hero_Section}>
        {/* <ContainerSection /> */}

        <div className={styles.product_review_section}>
          <h6>Product Review Reports</h6>
          {/* <div className={styles.dropdown_section}>
            <DropInput search={search} setSearch={setSearch} setProductSearch={setProductSearch} />
          </div> */}

        </div>


        {/* -------------table_section-------------- */}
        {data?.length > 0 ?
        <>
          <div className={styles.main_table}>
            <table className="table">
              <thead>
                <tr>
                  {/* <th scope="col">Sno</th> */}
                  <th className='px-5'>Seller Name</th>
                  <th >Subject</th>
                  <th >Customer</th>
                  <th >Product</th>
                  <th >Date</th>
                  <th >Status</th>
                  <th  className='text-center'>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr key={index}>
                    {/* <td>{index + 1}</td> */}
                    <td className='px-5 text-capitalize'>
                        {item?.seller?.name}
                    </td>
                    <td >{item.subject}</td>
                    <td className='text-capitalize'>{item.customer ? item.customer : "---"}</td>
                    <td className='text-capitalize'>{item?.productId?.name ? item?.productId?.name?.slice(0,15) : "---"}</td>
                    <td><Moment format="YYYY/MM/DD">{item.createdAt}</Moment></td>
                    <td>
                                          {item.status == "pending" ?   <div className='d-flex gap-3 align-items-center'>
                                                <FaRegCheckCircle className="text-success fs-5"
                                                //  onClick={() => handleAccept(item._id)} 
                                                onClick={()=> openApproveModal(item._id)}
                                                 />
                                                <RiCloseCircleLine 
                                                    className="text-danger fs-4 border-2" 
                                                    // onClick={() => handleReject(item._id)}
                                                    onClick={()=> openDisapprove(item._id)}
                                                     />
                                            </div>:(<p> {item.status == "resolved" ? (<span className='bg-success text-capitalize px-2 py-1 text-white rounded-3' style={{fontSize:"12px"}}>approved</span>) : <span className='bg-danger text-capitalize px-2 py-1 text-white rounded-3' style={{fontSize:"12px"}}>{item.status}</span>} </p>)}
                                        </td>
                    <td>
                      <div className={styles.image_section}>

                        <img onClick={() => openModal(item._id, item)} src="/image/eye.png" alt="eye" />
                       {item.status == "pending" &&  <img src="/image/delete.png" alt="delete" onClick={() => handleDelete(item?.reviewId?._id)} />}
                      </div>

                    </td>

                  </tr>
                ))}

              </tbody>
            </table>
            </div>
            <NewPagination {...paginationProps} />
          </>

          : <NotFound cls={styles.not_found}/>}
      </div>
      {isOpen && <ReviewReportModal func={getData} isOpen={isOpen} data={itemData} id={ID} closeModal={closeModal} />}
      {modalDisapprove && <WithDrawalModal type1="patch" status="reject" title="report" action={"Reject"} id={IDreject} show={modalDisapprove} handleClose={closeDisapproveModal} getData={getData} />}
      {modalVisible && <WithDrawalModal type1="patch" status="resolved" title="report" action={"Approve"} id={IDapprove} show={modalVisible} handleClose={closeApproveModal} getData={getData} />}
    </React.Fragment>
  )
}

export default ProductReport