import React, { useEffect, useState } from 'react'
import styles from './new.module.css'
import { Col, Row, Form } from 'react-bootstrap'
import TopNavbar from '../../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../../Component/NavbarSection/NavbarSection'
import { Helmet } from 'react-helmet'
import Cookies from 'js-cookie'
import { BASE_URL } from '../../../Apis/BaseUrl'
import axios from 'axios'
import { toast } from 'react-toastify'
import { fileChecker } from '../../../Component/FileChecker/fileChecker'
import ToasterUpdate from '../../../Component/Toaster/ToasterUpdate'
import { useNavigate, useParams } from 'react-router-dom'
import FileLabel from '../../../Component/FileLabel/FileLabel'
import "./new.css"
import FallProduct from '../../../Component/FallbackImgae/FallProduct'
// import AsyncSelectData from '../../../Component/AsyncSelectData/AsyncSelectData'
import AsyncSelect from 'react-select/async'

const EditCategories = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [parent, setParent] = useState('')
    const [name, setName] = useState('')
    const [file, setFile] = useState('')
    const [selectedImage, setSelectedImage] = useState('');
    const [categoryData, setCategoryData] = useState({})
    const [catType, setCatType] = useState("")
    const[commission, setCommission] = useState(0)
    const[affiliate_commission, setAffiliateCommission] = useState(0)

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
    };

    const { id } = useParams()
    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;
    const navigate = useNavigate()


    const getData = async () => {

        let register = `${BASE_URL}/category?limit=10&page=1`

        // console.log(register)
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        // console.log(res.data.data.docs[0])
        let resData = res?.data?.data?.docs?.map(item => ({
            value: item._id,
            label: item.name
        }));
        setData(resData)
        // setPageInfo({ ...res.data.data, docs: null })
    }
    useEffect(() => {
        getData()
    }, [])

       
    const getCatData = async (query = '') => {
        try {
            let register = `${BASE_URL}/category?limit=10&page=1&search=${query}`

            let res = await axios.get(register, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            return res.data.data.docs.map((element) => ({ label: element.name, value: element._id }))
        }
        catch (error) {
            console.error('Error while uploading data:', error);

            toast.error({
                render: error.message,
                type: 'error',
                isLoading: false,
                autoClose: 1500
            });
        }
    }

    const handleCatFeild = value => {
        setCategoryData(value)
    }

    
    const selectProps = {
        placeholder: "Select Category",
        data: data,
        dataHandler: getData,
        valueHandler: setCategoryData,
    }

    const getDataById = async () => {

        let register = `${BASE_URL}/category/${id}`

        // console.log(register)
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        console.log(res.data.data)
        setName(res.data.data?.name)
        setFile(res.data.data?.bannerUrl)
        setParent(res.data.data?.parent)
        setCatType(res.data.data?.type)
        setCommission(res.data.data?.commission)
        setAffiliateCommission(res.data.data?.affiliate_commission)
        setCategoryData({value:res.data.data?.parent})
        // setPageInfo({ ...res.data.data, docs: null })
    }
    useEffect(() => {
        getDataById()
    }, [])



    const handleEdit = async (e) => {
        e.preventDefault()

        const formData = new FormData();
        formData.append('name', name);
        formData.append('type', catType);
        formData.append('commission', commission);
        formData.append('affiliate_commission', affiliate_commission);
        // formData.append('category', priority);
        // if (parent) {
        //     formData.append('parent', categoryData.value);
        // }
       
        if (categoryData?.value) {
            formData.append('parent', categoryData.value);
        } else {
            formData.append('parent', '');
        }

        if (selectedImage) {
            let checkFile = fileChecker(selectedImage)
            if (!checkFile.value) {
                toast.error(checkFile.message)
                return
            }
            formData.append('banner', selectedImage);
        }

        console.log(selectedImage)
        let register = `${BASE_URL}/category/${id}`
        const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {

            let res = await axios.put(register, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token} `,
                },
            })
            console.log(res)
            if (!res.data.success) {
                ToasterUpdate(myToast, res.data.message, "error")
                return
            }
            ToasterUpdate(myToast, res.data.message, "success")
            // getData()
            navigate('/product/categories')
        } catch (error) {
            // console.log(error)
            ToasterUpdate(myToast, error.message, "error")
        }
        finally {
            setLoading(false)
            setSelectedImage(null)
            setName("")
            setParent('')
            setAffiliateCommission('')
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Add Categories</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />
            <Row className={styles.categories}>
                <Col className='mx-auto' md={10}>
                    <div className={styles.product_info}>
                        <div className={styles.product_info_section}>
                            <h6>Category Information</h6>
                        </div>
                        <div className={styles.main_section}>
                            <Form>
                                <div className={styles.input_container}>
                                    <label for="inputField"> Name:</label>
                                    <Form.Control type="text" placeholder="category name..." value={name} onChange={(e) => setName(e.target.value)} />

                                </div>
                                <div className={styles.input_container_brand}>
                                    <label for="inputField_2">Parent Category:</label>
                                    {/* <AsyncSelectData {...selectProps} /> */}
                                    <AsyncSelect className={styles.height} onInputChange={(text) => getCatData(text)} cacheOptions getOptionValue={e => e.value} loadOptions={getCatData} value={categoryData} defaultOptions onChange={handleCatFeild} />

                                    {/* <Form.Select required aria-label="Default select example" value={parent} onChange={(e) => setParent(e.target.value)}>
                                        {data && data.map((item, index) => (
                                            <option key={index} value={item._id}>{item.name}</option>
                                        ))}

                                    </Form.Select> */}
                                </div>
                                <div className={`${styles.input_container_unit}`}>
                                    <label style={{ fontSize: "14px", fontWeight: "500" }} >Category Type*</label>

                                    <select className="form-select" aria-label="Default select example" value={catType} onChange={(e) => setCatType(e.target.value)} required>
                                        <option value="interior" selected>Interior</option>
                                        <option value="exterior">Exterior</option>
                                        <option value="both">Both</option>

                                    </select>
                                </div>
                                <div className={`${styles.input_container} mt-4`}>
                                    <label for="inputField"> Commission(in %):</label>
                                    <Form.Control type="number" placeholder="enter commission" value={commission} onChange={(e) => setCommission(e.target.value)} />

                                </div>
                                <div className={`${styles.input_container} mt-4`}>
                                    <label for="inputField">Affiliate Commission:</label>
                                    <Form.Control type="number" placeholder="enter commission" value={affiliate_commission} onChange={(e) => setAffiliateCommission(e.target.value)} />

                                </div>
                                <div>
                                    <div className={`${styles.input_container_unit}`}>
                                    <label>Banner : <p className='mb-0'> (recommended size 277 * 158px) </p></label>

                                        {/* <label className={styles.label1}>Banner (200x200):</label> */}
                                        <FileLabel  id="attachment" file_label={styles.file_label}  setter={handleImageChange}
                                            //  onChange={(e) => setSelectedImage(e.target.files[0])} 
                                            placeholder='upload logo' />
                                        {/* <input type="file"
                                            onChange={(e) => setSelectedImage(e.target.files[0])}
                                            placeholder='Upload' /> */}
                                    </div>
                                    <div className=' mt-3 ml-margin'>
                                        <FallProduct imgData={file} cls={styles.img2} />
                                    </div>
                                </div>

                                <div className={styles.save_btn}>
                                    <button onClick={handleEdit} disabled={loading}>Save</button>
                                </div>

                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default EditCategories