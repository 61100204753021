import React, { useEffect, useState } from 'react';
import styles from './categorie.module.css'
import { useNavigate } from 'react-router-dom'
import TopNavbar from '../../Component/TopMenu/TopNavbar';
import NavbarSection from '../../Component/NavbarSection/NavbarSection';
import ContainerSection from '../../Component/ContainerSection/ContainerSection';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../Apis/BaseUrl';
import { toast } from 'react-toastify';
import ToasterUpdate from '../../Component/Toaster/ToasterUpdate';
import axios from 'axios';
import FallbackImage from '../../Component/FallbackImgae/FallbackImage';
import FallProduct from '../../Component/FallbackImgae/FallProduct';
import NewPagination from '../../Component/NewPagination/NewPagination';
import NotFound from '../../Component/NotFound/NotFound';


const Categories = () => {
    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [pageInfo, setPageInfo] = useState({})
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;

    const getData = async () => {
        // console.log(register)
        let res = await axios.get(`${BASE_URL}/category?limit=${limit}&page=${page}&active=${search}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token} `,
            },
        })
        console.log(res.data.data)
        setData(res.data.data?.docs)
        setPageInfo({ ...res.data.data, docs: null })
    }

    useEffect(() => {
        getData()
    }, [limit, page, search]
    )
    const paginationProps = {
        setPage,
        pageInfo, setLimit
      }

    const handleAccept = async (id) => {
        // e.preventDefault()
        console.log(id)
        let register = `${BASE_URL}/category/${id}/feature`
        const myToast = toast.loading('Please Wait...')
        // console.log(register)
        try {

            let res = await axios.put(register, {}, {
                // withCredentials : true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token} `,
                },
            })

            if (!res.data.success) {
                ToasterUpdate(myToast, res.data.message, "error")
            }
            ToasterUpdate(myToast, res.data.message, "success")
            getData()
        } catch (error) {
            // console.log(error)
            ToasterUpdate(myToast, error.message, "error")
        }

    };
    const handleActive = async (id, item) => {
        // console.log(item)
        // e.preventDefault()
        // console.log(id)
        let register = `${BASE_URL}/category/${id}`
        const myToast = toast.loading('Please Wait...')
        // console.log(register)
        try {

            let res = await axios.put(register, { active: !item.active }, {
                // withCredentials : true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token} `,
                },
            })

            if (!res.data.success) {
                ToasterUpdate(myToast, res.data.message, "error")
            }
            ToasterUpdate(myToast, res.data.message, "success")
            getData()
        } catch (error) {
            // console.log(error)
            ToasterUpdate(myToast, error.message, "error")
        }

    };

    const handleDelete = async (id) => {
        console.log(id)
        const myToast = toast.loading('Please Wait...')
        setLoading(true)
        try {
            let register = `${BASE_URL}/category/${id}`

            const response = await axios.delete(register,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    }
                });
            console.log(response)
            toast.update(myToast, {
                render: response.data.message,
                type: 'success',
                isLoading: false,
                autoClose: 1500
            });

            getData()
        } catch (error) {
            toast.update(myToast, {
                render: error.message,
                type: 'error',
                isLoading: false,
                autoClose: 1500
            })
            console.error('Error deleting resource:', error);
        }
        finally {
            setLoading(false)
        }
    }
    const navigateToEdit = (id) => {
        navigate(`/product/editcategories/${id}`)
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>Categories</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* -----------------hero-section---------------- */}

            <div className={styles.hero_Section}>
                <ContainerSection />

                <div className={styles.filter_section}>
                    <div className={styles.filter}>
                        <p>Filters : </p>
                        <p>Status </p>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2" value=""    onChange={(e) => setSearch(e.target.value)} checked={search == ""} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                All
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="active"    onChange={(e) => setSearch(e.target.value)} checked={search == "active"} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Active
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                            value="inactive"    onChange={(e) => setSearch(e.target.value)}
                                checked={search == "inactive"} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Inactive
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                            value="interior"    onChange={(e) => setSearch(e.target.value)}
                                checked={search == "interior"} />
                            <label className="form-check-label" for="flexRadioDefault2">
                            Interior
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                            value="exterior"    onChange={(e) => setSearch(e.target.value)}
                                checked={search == "exterior"} />
                            <label className="form-check-label" for="flexRadioDefault2">
                            Exterior
                            </label>
                        </div>

                    </div>
                    <div className={styles.btn_section}>
                        <button onClick={() => navigate(`/product/addcategories`)}>Add Categories</button>
                    </div>

                </div>


                {/* -------------table_section-------------- */}

                {data?.length > 0 ?  
                <><div className={styles.main_table}>
                    <table className="table">
                        <thead>
                            <tr>
                                {/* <th scope="col"><input type="radio" /></th> */}
                                {/* <th scope="col">#</th> */}
                                <th  className='px-5'>Banner</th>
                                <th > Name</th>
                                <th >Parent Category</th>
                                <th >Commission</th>
                                <th>Type</th>
                                {/* <th scope="col">Available</th> */}
                                <th>Status</th>
                                {/* <th scope="col">Feature</th> */}
                                <th className='text-center'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((item, index) => (
                                <tr>
                                    {/* <th scope="row"><input type="radio" /></th> */}
                                    {/* <td>{index + 1}</td> */}
                                    <td className='px-5'><FallProduct cls={styles.table_img} imgData={item.bannerUrl} alt="wood" /></td>
                                    <td className='text-capitalize'>{item?.name}</td>
                                    <td  className='text-capitalize'>{item?.parent?.name ? item?.parent?.name : "---" }</td>
                                    <td>{item?.commission ? item?.commission : "0" }</td>
                                    <td  className='text-capitalize'>{item?.type ? item.type : "---"}</td>
                                    {/* <td><input type="number" placeholder='250' /></td> */}
                                    <td>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onChange={() => handleActive(item._id, item)} checked={item.active} />
                                        </div>
                                        {/* {item?.active == true ? "Active" : "Inactive"} */}
                                    </td>
                                    {/* <td>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onChange={() => handleAccept(item._id)} checked={item.featured} />
                                        </div>
                                    </td> */}
                                    <td>
                                        <div className={styles.image_section}>
                                            <img src="/image/edit.png" alt="edit" onClick={() => navigateToEdit(item._id)} />
                                            <img src="/image/delete.png" alt="delete" onClick={() => handleDelete(item._id)} />
                                        </div>

                                    </td>
                                </tr>
                            ))}

                            {/* <tr>
                                <th scope="row"><input type="radio" /></th>
                                <td>1</td>
                                <td><img src="/image/wood.png" alt="wood" /></td>
                                <td>caulk-adhesive</td>
                                <td>--</td>
                                <td><input type="number" placeholder='10 ' /></td>
                                <td>6%</td>
                                <td>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked />
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.image_section}>
                                        <img src="/image/edit.png" alt="edit" />
                                        <img src="/image/delete.png" alt="delete" />                                    </div>

                                </td>
                            </tr> */}
                        </tbody>
                    </table>
                    </div>
                    <NewPagination {...paginationProps}/>

                </>
                                : <NotFound cls={styles.not_found}/> }

            </div>

        </React.Fragment>
    )
}

export default Categories