import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import styles from './review.module.css'
import ReviewModal from './ReviewModal/ReviewModal'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { BASE_URL } from '../../Apis/BaseUrl'
import axios from 'axios'
import { toast } from 'react-toastify'
import ToasterUpdate from '../../Component/Toaster/ToasterUpdate'
import DropInput from '../../Component/DropDownComp/DropInput'
import NewPagination from '../../Component/NewPagination/NewPagination'
import NotFound from '../../Component/NotFound/NotFound'
import FallProduct from '../../Component/FallbackImgae/FallProduct'

const ProductReview = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState([])
  const [data2, setData2] = useState([])
  const [page, setPage] = useState(1)
  const [pageInfo, setPageInfo] = useState({})
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [search1, setSearch1] = useState('')
  const [loading, setLoading] = useState(false)
  const [ID, setID] = useState('')
  const [itemData, setItemData] = useState({})
  const [productSearch, setProductSearch] = useState('')


  const navigate = useNavigate()
  let profileTokenJson = Cookies.get("v&b_token");
  let profileToken = JSON.parse(profileTokenJson);
  // console.log(profileToken.token)
  let token = profileToken.token;

  const getData = async () => {
    // console.log(register)
    let res = await axios.get(`${BASE_URL}/review?limit=${limit}&page=${page}&product=${productSearch}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token} `,
      },
    })
    console.log(res.data.data)
    setData(res.data.data?.docs)
    setPageInfo({ ...res.data.data, docs: null })
  }

  useEffect(() => {
    getData()
  }, [limit, page, productSearch, search])



  const handleAccept = async (id) => {
    // e.preventDefault()
    console.log(id)
    let register = `${BASE_URL}/review/${id}/publish-toggle`
    const myToast = toast.loading('Please Wait...')
    // console.log(register)
    try {

      let res = await axios.patch(register, {}, {
        // withCredentials : true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token} `,
        },
      })

      if (!res.data.success) {
        ToasterUpdate(myToast, res.data.message, "error")
      }
      ToasterUpdate(myToast, res.data.message, "success")
      getData()
    } catch (error) {
      // console.log(error)
      ToasterUpdate(myToast, error.message, "error")
    }

  };
  const handleDelete = async (id) => {
    console.log(id)
    const myToast = toast.loading('Please Wait...')
    setLoading(true)
    try {
      let register = `${BASE_URL}/review/${id}/remove`

      const response = await axios.delete(register,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        });
      console.log(response)
      toast.update(myToast, {
        render: response.data.message,
        type: 'success',
        isLoading: false,
        autoClose: 1500
      });

      getData()
    } catch (error) {
      toast.update(myToast, {
        render: error.message,
        type: 'error',
        isLoading: false,
        autoClose: 1500
      })
      console.error('Error deleting resource:', error);
    }
    finally {
      setLoading(false)
    }
  }

  const closeModal = (id) => {
    setIsOpen(false)
  }
  const openModal = (id, item) => {
    setIsOpen(true)
    setID(id)
    setItemData(item)
  }

  const paginationProps = {
    setPage, pageInfo, setLimit
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>Product Review</title>
      </Helmet>
      <TopNavbar />
      <NavbarSection />

      {/* ----------Hero-Section----------- */}

      <div className={styles.hero_Section}>
        {/* <ContainerSection /> */}

        <div className={styles.product_review_section}>
          <h6>Product Review</h6>
          {/* <div className={styles.dropdown_section}>
            <DropInput search={search} setSearch={setSearch} setProductSearch={setProductSearch} />
          </div> */}

        </div>


        {/* -------------table_section-------------- */}
        {data?.length > 0 ?
        <>
          <div className={styles.main_table}>
            <table className="table">
              <thead>
                <tr>
                  {/* <th scope="col">Sno</th> */}
                  <th  className='px-5'>Product Image</th>
                  <th >Product Name</th>
                  <th >Seller Name</th>
                  <th>Comment</th>
                  <th>Customer</th>
                  <th>Published</th>
                  <th className='text-center'>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr>
                    {/* <td>{index + 1}</td> */}
                    <td className='px-5'>
                      <FallProduct cls={styles.img_css} imgData={item?.productId?.url} />
                    </td>
                    <td  className='text-capitalize'>{item?.productId?.name?.length > 20 ? item?.productId?.name?.slice(0,20) + "..." : item?.productId?.name}</td>
                    <td  className='text-capitalize'>{item?.productId?.seller?.name}</td>
                    <td>{item?.comment?.length > 20 ? item?.comment?.slice(0,20) + "..." : item?.comment}</td>
                    <td  className='text-capitalize'>{item?.user?.name ? item?.user?.name : "---"}</td>
                    <td>
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onChange={() => handleAccept(item._id)} checked={item.approved} />
                      </div>
                    </td>
                    <td>
                      <div className={styles.image_section}>

                        <img onClick={() => openModal(item._id, item)} src="/image/eye.png" alt="eye" />
                        <img src="/image/delete.png" alt="delete" onClick={() => handleDelete(item._id)} />
                      </div>

                    </td>

                  </tr>
                ))}

              </tbody>
            </table>
            </div>
            <NewPagination {...paginationProps} />
          </>

          : <NotFound cls={styles.not_found}/>}
      </div>
      {isOpen && <ReviewModal func={getData} isOpen={isOpen} data={itemData} id={ID} closeModal={closeModal} />}

    </React.Fragment>
  )
}

export default ProductReview