import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import styles from './email.module.css'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { BASE_URL } from '../../Apis/BaseUrl'
import axios from 'axios'
import Moment from 'react-moment'
import NewPagination from '../../Component/NewPagination/NewPagination'
import NotFound from '../../Component/NotFound/NotFound'
import { FiDownload } from "react-icons/fi";


const Subscribe = () => {
    const[data, setData] = useState([])
    const[page, setPage] = useState(1)
    const[pageInfo, setPageInfo] = useState({})
    const[limit, setLimit] = useState(10)
    const[search, setSearch] = useState('')
    const[status, setStatus] = useState('')
    
    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;
    const getData = async () => {
     
        let register = `${BASE_URL}/subscribed-emails?limit=${limit}&page=${page}&search=${search}&account=${status}`
    
        // console.log(register)
        let res = await axios.get(register, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
        console.log(res.data.data)
        setData(res.data.data.docs)
        setPageInfo({ ...res.data.data, docs: null })
      }

      useEffect(() => {
        getData()
    }, [limit, search, page, status])

    const paginationProps = {
        setPage,
        pageInfo,setLimit
      }
    return (
        <React.Fragment>
            <Helmet>
                <title>Subscribe Email</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* ----------Hero-Section----------- */}

            <div className={styles.hero_Section}>
            <div className={styles.filter_section}>
                    <div className={styles.filter}>
                        <p>Filter Account : </p>
                        <p>Status </p>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio"
                                name="flexRadioDefault" id="flexRadioDefault2"
                                value=""
                                onChange={(e) => setStatus(e.target.value)}
                                checked={status === ''} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                All
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                value="1"
                                onChange={(e) => setStatus(e.target.value)} checked={status === '1'} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Yes
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                value="0"
                                onChange={(e) => setStatus(e.target.value)} checked={status === '0'} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                No
                            </label>
                        </div>
                       
                        </div>
                        </div>
                
                <div className={`d-flex justify-content-between align-items-center ${styles.inn}`}>
                    <h6 className='my-4'>Subscribe Email</h6>
                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} style={{
                        height: "40px",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #d9d9d9", outline: "none"
                    }} placeholder="Search..." required />

                </div>


                {/* -------------table_section-------------- */}
{data?.length > 0 ? 
<>
                <div className={styles.main_table}>
                    <table className="table">
                        <thead>
                            <tr>
                                {/* <th scope="col">Sno</th> */}
                                <th className='px-5'>Email ID</th>
                                <th >Account</th>
                                <th >Date</th>
                                <th >Action</th>
                            </tr>
                        </thead>
                        <tbody>
                           {data?.map((item, index) => (
                             <tr>
                             {/* <td>{index+1}</td> */}
                             <td className='px-5'>{item.email}</td>
                             <td>{item.hasAccount == 0 ? "no" : "yes"}</td>
                             <td><Moment format="YYYY/MM/DD" utc>{item.createdAt}</Moment></td>
                             <td >
                                <button style={{fontSize: "16px"}} className={styles.btn1}><FiDownload />
</button>
                             </td>
                         </tr>
                           ))}

                        </tbody>
                    </table>
                    </div>
                    <NewPagination {...paginationProps}/>
                </>

: <NotFound cls={styles.not_found}/> }
            </div>
        </React.Fragment>
    )
}

export default Subscribe