import React, { useEffect, useState } from 'react'
import classes from './comp.module.css'
import Cookies from 'js-cookie'
import { BASE_URL } from '../../../Apis/BaseUrl'
import CompCard from './CompCard'
import axios from 'axios'
import AddMaterial from '../../Apperance/Modal/AddMaterial'
import NewPagination from '../../../Component/NewPagination/NewPagination'
import ToasterUpdate from '../../../Component/Toaster/ToasterUpdate'
import { toast } from 'react-toastify'
import UpdateMaterial from '../../Apperance/Modal/UpdateMaterial'

const Material = () => {

    const [show, setShow] = useState(false)

    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [pageInfo, setPageInfo] = useState({})
    const [limit, setLimit] = useState(10)
    const [loading, setLoading] = useState(false)
    const [currentObject, setCurrentObject] = useState({})
    const [show2, setShow2] = useState(false)

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    let token = profileToken.token;

    const getData = async () => {

        let register = `${BASE_URL}/visualizer/material?limit=${limit}&page=${page}`

        // console.log(register)
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        setData(res.data.data.docs)
        setPageInfo({ ...res.data.data, docs: null })
    }

    useEffect(() => {
        getData()
    }, [page, limit])

    const paginationProps = {
        setPage,
        pageInfo, setLimit
    }


    function handleUpdate() {
        setCurrentObject(this)
        setShow2(true)
    }

    async function handleDelete(id) {
        const myToast = toast.loading('Please Wait...')
        let register = `${BASE_URL}/visualizer/material/${id}/remove`

        let res = await axios.delete(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })

        if (!res.data.success) {
            ToasterUpdate(myToast, res.data.message, 'error')
            return;
        }

        ToasterUpdate(myToast, res.data.message, 'success')
        getData()
    }


    return (
        <React.Fragment>

            <div className={classes.container}>
                <div className='d-flex justify-content-between align-items-center mt-3'>
                    <h6 style={{ color: "#AE0000" }}>Material</h6>
                    <button onClick={() => setShow(true)} className='px-3 py-2 rounded-1 border-0' style={{ backgroundColor: "#AE0000", color: "white" }}>Add Material</button>
                </div>
                {loading ? 'Loading' :
                    data.length > 0 ?
                        data?.map((item) => (
                            <CompCard handleUpdate={handleUpdate.bind(item)} handleDelete={() => handleDelete(item._id)} label={'pattern'} data={item} func={getData} />
                        )) : 'No Data Found!'
                }

                <NewPagination {...paginationProps} />

            </div>

            {show && <AddMaterial closeModal={() => setShow(false)} getData={getData} isOpen={show} />}
            {show2 && <UpdateMaterial closeModal={() => setShow2(false)} data={currentObject} getData={getData} isOpen={show2} />}
        </React.Fragment>
    )
}

export default Material