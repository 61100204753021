import React, { useEffect, useState } from 'react'
import styles from './sales.module.css'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Cookies from 'js-cookie'
import { BASE_URL } from '../../Apis/BaseUrl'
import axios from 'axios'
import NewPagination from '../../Component/NewPagination/NewPagination'
import PDFFile from '../../Component/PDFViewer/PDFFile'
import { PDFDownloadLink } from '@react-pdf/renderer'
import NotFound from '../../Component/NotFound/NotFound'
import formatter from '../../Component/NumberFormatter/formatter'
import { FormatNumber } from '../../Component/FormatNumber/FormatNumber'
const td1 = [
    {
        h5: 'Total Orders',
        p: '0'
    },
    {
        h5: 'Today Orders',
        p: '0',
    },
    {
        h5: 'Confirmed Orders',
        p: '0'
    },
    {
        h5: 'Shipped Orders',
        p: '0'
    },
    {
        h5: 'Recent Refund Products',
        p: '0'
    }
]


const AllOrder = () => {
    const [tileData, setTileData] = useState({})

    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [pageInfo, setPageInfo] = useState({})
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [searchByPayment, setSearchByPayment] = useState('')
    const [searchByOrder, setSearchByOrder] = useState('')


    const navigate = useNavigate()
    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;
    const getTileData = async () => {

        let register = `${BASE_URL}/order/tiles`
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        console.log(res.data.data)
        td1[0].p = res.data.data?.totalOrders
        td1[1].p = res.data.data?.todayOrders
        td1[2].p = res.data.data?.confirmedOrders
        td1[3].p = res.data.data?.shippedOrders
        td1[4].p = res.data.data?.recentRefundProducts
        setTileData(res.data.data)
    }

    useEffect(() => {
        getTileData()
    }, [])
    const getData = async () => {

        let register = `${BASE_URL}/order?limit=${limit}&page=${page}&search=${search}&paymentStatus=${searchByPayment}&orderStatus=${searchByOrder}`

        // console.log(register)
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        console.log(res.data.data.docs)
        setData(res.data.data.docs)
        setPageInfo({ ...res.data.data, docs: null })
    }

    useEffect(() => {
        getData()
    }, [limit, page, search, searchByPayment, searchByOrder])

    const paginationProps = {
        setPage,
        pageInfo,setLimit
    }

    // const downloadPdf = async () => {
    //     try {
    //         const response = await axios.get('https://react-pdf.org/');
    //         const blob = new Blob([response.data], { type: 'application/pdf' });
    //         saveAs(blob, 'react-pdf-document.pdf');
    //     } catch (error) {
    //         console.error('Error downloading PDF:', error);
    //     }
    // };
    return (
        <React.Fragment>
            <Helmet>
                <title>All Order</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* -----------------hero-section---------------- */}

            <div className={styles.hero_Section}>
                <ContainerSection data={td1} />

                <div className={styles.mainSection}>
                    <Row className='w-100 d-flex align-items-center'>
                        <Col md={4}>
                            <h6 className='mb-0 '>Orders</h6>
                        </Col>

                        <Col md={8} className='p-0'>
                            <div className={styles.input_section}>
                                <select className="form-select" aria-label="Default select example" onChange={(e) => setSearchByPayment(e.target.value)} value={searchByPayment}>
                                    <option value=""> Filter by Payment Status</option>
                                    <option value="paid" selected>Paid (online payments)</option>
                                    <option value="pending">Pending (COD payments)</option>
                                </select>
                                <select className="form-select" aria-label="Default select example" onChange={(e) => setSearchByOrder(e.target.value)} value={searchByOrder}>
                                    <option value="">Filter by Delivery Status</option>
                                    <option value="pending" selected>Pending</option>
                                    <option value="processing">Processing</option>
                                    <option value="shipped">Shipped</option>
                                    <option value="delivered">Delivered</option>
                                    <option value="cancelled">Cancelled</option>
                                    <option value="return">Return</option>
                                </select>

                                <input type="text" className="form-control" placeholder="Search by Order Id" value={search} onChange={(e) => setSearch(e.target.value)} required />

                            </div>
                        </Col>

                    </Row>
                </div>



                {/* -------------table_section-------------- */}
                {data?.length > 0 ?
                <>
                    <div className={styles.main_table}>
                        <table className="table">
                            <thead>
                                <tr>
                                    {/* <th scope="col">#</th> */}
                                    <th  className='px-5'>Order Id</th>
                                    <th >Num. of Products</th>
                                    <th >Customer</th>
                                    <th >Amount</th>
                                    <th >Delivery/Pickup Status</th>
                                    <th >Payment Method</th>
                                    <th >Payment Status</th>
                                    <th >Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((item, index) => (
                                    <tr>
                                        {/* <td>{index + 1}</td> */}
                                        <td className='px-5'>#{item?.orderId}</td>
                                        <td>{item?.qty}</td>
                                        <td className='text-capitalize'>{item?.customerId?.name}</td>

                                        <td >{FormatNumber(item?.total || 0)}</td>
                                        <td className='text-capitalize'>{item?.orderStatus}</td>
                                        <td className='text-capitalize'>{item?.paymentMethod}</td>
                                        {item.paidStatus == "paid" && <td className='text-capitalize'><span className="badge" style={{backgroundColor:"green"}}>{item.paidStatus}</span></td>}
                                        {item.paidStatus == "pending" && <td className='text-capitalize'><span className="badge bg-danger" >{item.paidStatus}</span></td>}
                                        {/* <td><span class="badge bg-danger">{item.paidStatus}</span></td> */}
                                        <td>
                                            <div className={styles.image_section}>
                                                <img onClick={() => navigate(`/sales/allorder/orderdetails/${item._id}`)} src="/image/eye.png" alt="edit" />
                                                {/* <PDFDownloadLink document={<PDFFile />} filename="FORM">
                                                      {({ loading }) => (loading ? <button>Loading Document...</button> : <img src="/image/download.png" alt="download" />)}
                                                </PDFDownloadLink> */}
                                          
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                               

                            </tbody>
                        </table>
                        </div>
                        <NewPagination {...paginationProps} />
                    </>
                    : <NotFound cls={styles.not_found} />}

            </div>
        </React.Fragment>
    )
}

export default AllOrder