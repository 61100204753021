import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Form } from 'react-bootstrap'
import styles from './customer.module.css'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'
import Cookies from 'js-cookie'
import axios from 'axios'
import { BASE_URL } from '../../Apis/BaseUrl'
import NewPagination from '../../Component/NewPagination/NewPagination'
import { toast } from 'react-toastify'
import ToasterUpdate from '../../Component/Toaster/ToasterUpdate'
import NotFound from '../../Component/NotFound/NotFound'
import { BsThreeDots } from 'react-icons/bs'
import DeleteModal from '../../Component/DeleteModal/DeleteModal'

const Customer = () => {
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [pageInfo, setPageInfo] = useState({})
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [toggleData, setToggleData] = useState('')
  const [status, setStatus] = useState('')
  const [modalVisible, setModalVisible] = useState(false);
  const[ID, setId] = useState('')

  const openModal = (id) => {
    // console.log(id)
    setId(id)
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };


  let profileTokenJson = Cookies.get("v&b_token");
  let profileToken = JSON.parse(profileTokenJson);
  // console.log(profileToken.token)
  let token = profileToken.token;

  const toggleMenu = (id) => {
    // console.log("fgfg")
    // console.log(id)
    let div_id = id?.target?.id
    // console.log(div_id)
    if (div_id) {
      // console.log("1")
      if (div_id == toggleData) {
        setToggleData('')
        // console.log("2")

      } else {
        setToggleData(div_id)
        // console.log("3")
      }
    } else {
      setToggleData('')
      // console.log(4)
    } 
  }

    const getData = async () => {

      let register = `${BASE_URL}/user?limit=${limit}&page=${page}&search=${search}&status=${status}`

      // console.log(register)
      let res = await axios.get(register, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      // console.log(res.data.data.docs)
      setData(res.data.data?.docs)
      setPageInfo({ ...res.data.data, docs: null })
    }

    useEffect(() => {
      getData()
    }, [limit, page, search, status])
    const paginationProps = {
      setPage,
      pageInfo,setLimit
    }

    const handleDelete = async (id) => {
      console.log(id)
      const myToast = toast.loading('Please Wait...')
      setLoading(true)
      try {
        let register = `${BASE_URL}/user/${id}`

        const response = await axios.delete(register,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`
            }
          });
        console.log(response)
        toast.update(myToast, {
          render: response.data.message,
          type: 'success',
          isLoading: false,
          autoClose: 1500
        });

        getData()
      } catch (error) {
        toast.update(myToast, {
          render: error.message,
          type: 'error',
          isLoading: false,
          autoClose: 1500
        })
        console.error('Error deleting resource:', error);
      }
      finally {
        setLoading(false)
      }
    }

    const handleAccept = async (id, item) => {
      // e.preventDefault()
      console.log(item)
      let register = `${BASE_URL}/vendor/${id}/approval`
      const myToast = toast.loading('Please Wait...')
      // console.log(register)
      try {

        let res = await axios.patch(register, { active: !item.active }, {
          // withCredentials : true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token} `,
          },
        })

        if (!res.data.success) {
          ToasterUpdate(myToast, res.data.message, "error")
        }
        ToasterUpdate(myToast, res.data.message, "success")
        getData()
      } catch (error) {
        // console.log(error)
        ToasterUpdate(myToast, error.message, "error")
      }

    };

    
  const vendorLoginHandler = async (id) => {
    const myToast = toast.loading('Please Wait...');
    try {
      const register = `${BASE_URL}/user/${id}/token`;
      const response = await fetch(register, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to authenticate user');
      }
  
      const data = await response.json();

      console.log(data)
  
      toast.update(myToast, {
        render: data.message,
        type: 'success',
        isLoading: false,
        autoClose: 1500
      });  
      const newPageUrl = `https://visual-and-builds.netlify.app/session?auth=${data.data.token}`;
      window.open(newPageUrl, '_blank');
    } catch (error) {
      toast.update(myToast, {
        render: error.message,
        type: 'error',
        isLoading: false,
        autoClose: 1500
      });
    }
  };


    return (
      <React.Fragment>
        <Helmet>
          <title>Customer</title>
        </Helmet>
        <TopNavbar />
        <NavbarSection />

        {/* ----------Hero-Section----------- */}

        <div className={styles.hero_Section} onClick={(e) => toggleMenu(e)}>
          <ContainerSection />

          <div className={styles.filter_section}>
                    <div className={styles.filter}>
                        <p>Filters : </p>
                        <p>Status </p>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio"
                                name="flexRadioDefault" id="flexRadioDefault2"
                                value=""
                                onChange={(e) => setStatus(e.target.value)}
                                checked={status === ''} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                All
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                value="active"
                                onChange={(e) => setStatus(e.target.value)} checked={status === 'active'} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Active
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                value="inactive"
                                onChange={(e) => setStatus(e.target.value)} checked={status === 'inactive'} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Inactive
                            </label>
                        </div>
                       
                        
                        

                    </div>
                    {/* <div className={styles.btn_section}>
                        <button>Add Product</button>
                    </div> */}
                </div>

          <div className={styles.product_review_section}>
            <h6 className='mb-0'>Customers</h6>
      <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} style={{
                        height: "40px",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #d9d9d9", outline: "none"
                    }} placeholder="Search..." required />

          </div>


          {/* -------------table_section-------------- */}
          {data.length > 0 ?
            <>
              <div className={styles.main_table}>
                <table className="table">
                  <thead>
                    <tr>

                      <th className='px-5'>V&B ID</th>
                      <th >Name</th>
                      <th >Phone</th>
                      <th >Email ID</th>
                      <th >Address</th>
                      <th >Status</th>

                      <th >Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => (
                      <tr>

                        <td className='px-5'>{item?.vbID}</td>
                        <td className='text-capitalize' >{item?.name}</td>
                        <td>{item.phone ? item.phone : "---"}</td>
                        <td>{item?.email}</td>
                        <td className='text-capitalize'>{item?.address}</td>
                        <td>
                          <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onChange={() => handleAccept(item._id, item)} checked={item.active} />
                          </div>
                        </td>
                        
                        <td><BsThreeDots style={{cursor:"pointer"}} size={22} id={item._id} onClick={() => toggleMenu(item._id)} />
                          <div className='d-flex justify-content-end position-relative'>
                            <div className={styles.list_section}>
                              {item._id == toggleData && (
                                <div className={styles.menu}>
                                  <ul>
                                    <li onClick={()=>vendorLoginHandler(item._id)}>Log in as Customer</li>
                                  
                                    <li onClick={() => openModal(item._id)}
                                    //  onClick={() => handleDelete(item._id)}
                                     >Delete Customer</li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                        

                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>
              <NewPagination {...paginationProps} />
            </>
            : <NotFound cls={styles.not_found} />}


        </div>
        {modalVisible && <DeleteModal title="customer" id={ID} show={modalVisible} handleClose={closeModal} getData={getData} />}
      </React.Fragment>
    )
  }

  export default Customer