import React, { useEffect, useState } from 'react'
import styles from './refund.module.css'
import { Row, Col } from 'react-bootstrap'
import RefundModal from './Modal/RefundModal'
import TopNavbar from '../../Component/TopMenu/TopNavbar'
import NavbarSection from '../../Component/NavbarSection/NavbarSection'
import ContainerSection from '../../Component/ContainerSection/ContainerSection'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { BASE_URL } from '../../Apis/BaseUrl'
import axios from 'axios'
import NewPagination from '../../Component/NewPagination/NewPagination'
import Moment from 'react-moment'
import { toast } from 'react-toastify'
import ToasterUpdate from '../../Component/Toaster/ToasterUpdate'
import { FaRegCheckCircle } from 'react-icons/fa'
import { TiDelete } from "react-icons/ti";
import { RiCloseCircleLine } from "react-icons/ri";
import NotFound from '../../Component/NotFound/NotFound'
import formatter from '../../Component/NumberFormatter/formatter'
import ApproveModal from '../../Component/DeleteModal/ApproveModal'
import { FormatNumber } from '../../Component/FormatNumber/FormatNumber'

const td1= [
    {
      h5: 'Total Refund Request',
      p: '0'
    },
    {
      h5: 'Total Refund Request Amount',
      p: '0',
    },
    {
      h5: 'Today Refund Request',
      p: '0'
    },
    {
      h5: 'Today Refund Request Amount',
      p: '0'
    },
    {
      h5: 'Approved Refund Amount',
      p: '0'
    }
  ]

const RefundRequest = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [data, setData] = useState([])
    const [tileData, setTileData] = useState({})
    const [page, setPage] = useState(1)
    const [pageInfo, setPageInfo] = useState({})
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [ID, setID] = useState('')
    const [modalVisible, setModalVisible] = useState(false);
  const[IDapprove, setIdapprove] = useState('')
    const [modalDisapprove, setModalDisapprove] = useState(false);
    const[status, setStatus] = useState('')
  const[IDreject, setIdReject] = useState('')

  const openApproveModal = (id) => {
    // console.log(id)
    setIdapprove(id)
    setModalVisible(true);
  };

  const closeDisapproveModal = () => {
    setModalDisapprove(false);
  };
  const openDisapprove= (id) => {
    // console.log(id)
    setIdReject(id)
    setModalDisapprove(true);
  };

  const closeApproveModal = () => {
    setModalVisible(false);
  };

    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    let token = profileToken.token;
    
        const getTileData = async () => {
     
        let register = `${BASE_URL}/refund/tiles`
        let res = await axios.get(register, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
        console.log(res.data.data)
        td1[0].p = res.data.data?.totalRefundRequest
        td1[1].p= formatter.format(res.data.data?.totalRefundRequestAmount)
        td1[2].p= res.data.data?.todayRefundRequest
        td1[3].p= res.data.data?.todayRefundRequestAmount
        td1[4].p= formatter.format(res.data.data?.approvedRefundAmount || 0)
        setTileData(res.data.data)
      }

      useEffect(() => {
        getTileData()
    }, [])


    const getData = async () => {

        let register = `${BASE_URL}/refund?limit=${limit}&page=${page}&search=${search}&status=${status}`

        // console.log(register)
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        console.log(res.data.data)
        setData(res.data.data?.docs)
        setPageInfo({ ...res.data.data, docs: null })
    }

    useEffect(() => {
        getData()
    }, [limit, page, search, status])
    const paginationProps = {
        setPage,
        pageInfo, setLimit
    }
    const closeModal = () => {
        setIsOpen(false)
    }
    const openModal = (id) => {
        setIsOpen(true)
        setID(id)
    }

    const handleAccept = async (id) => {
        // e.preventDefault()
        console.log(id)
        let register = `${BASE_URL}/refund/${id}/approve`
        const myToast = toast.loading('Please Wait...')
        // console.log(register)
        try {

            let res = await axios.put(register, {}, {
                // withCredentials : true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token} `,
                },
            })

            if (!res.data.success) {
                ToasterUpdate(myToast, res.data.message, "error")
            }
            ToasterUpdate(myToast, res.data.message, "success")
            getData()
        } catch (error) {
            // console.log(error)
            ToasterUpdate(myToast, error.message, "error")
        }

    };
   

    const navigate = useNavigate()

    const handleNavigate = (id) => {
        navigate(`/sales/allorder/orderdetails/${id}`)
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>Refund Request</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* -----------------hero-section---------------- */}

            <div className={styles.hero_Section}>
            <div className={styles.filter_section}>
                    <div className={styles.filter}>
                        <p>Filters : </p>
                        <p>Status </p>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio"
                                name="flexRadioDefault" id="flexRadioDefault2"
                                value=""
                                onChange={(e) => setStatus(e.target.value)}
                                checked={status === ''} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                All
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                value="approved"
                                onChange={(e) => setStatus(e.target.value)} checked={status === 'approved'} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Approved
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                value="pending"
                                onChange={(e) => setStatus(e.target.value)} checked={status === 'pending'} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Pending
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                value="rejected"
                                onChange={(e) => setStatus(e.target.value)} checked={status === 'rejected'} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Rejected
                            </label>
                        </div>
                        </div>
                        </div>
                {/* <ContainerSection data={td1} /> */}
                <div className={`d-flex justify-content-between align-items-center ${styles.inn}`}>
                    <h6 className='my-4'>All Refund Requests</h6>
                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} style={{
                        height: "40px",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #d9d9d9", outline: "none"
                    }} placeholder="Search by Order ID, Seller name, Product Name..." required />

                </div>

                        {/* -------------table_section-------------- */}
                {data?.length > 0 ?
                <>
                    <div className={styles.main_table}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th  className='px-5'>Order ID</th>
                                    <th>Date</th>
                                    <th style={{width:"400px"}} >Seller Name</th>
                                    <th style={{width:"500px"}}>Product</th>
                                    <th >Amount</th>
                                    <th >Status</th>
                                    <th >Reason</th>
                                    <th >Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((item, index) => (
                                    <tr>
                                        <td style={{cursor:"pointer"} }  className='px-5' onClick={()=>handleNavigate(item?.orderId?._id)}>#{item?.orderId?.orderId}</td>
                                        <td><Moment format='YYYY/MM/DD' utc>{item?.createdAt}</Moment></td>
                                        <td style={{width:"400px"}} className='text-capitalize'>{item?.seller?.name}</td>
                                        <td className='text-capitalize' style={{width:"500px"}}>
                                        <a style={{textDecoration:"none", color:"black"}} href={`https://visual-build.netlify.app/product/${item?.productId?.slug}?id=${item?.productId?._id}`} target='_blank'> {item?.productId ? item?.productId?.name?.length > 10 ? item?.productId?.name.slice(0, 20) + "..." : item?.productId?.name : "_ _ _"} </a> </td>
                                        <td> {FormatNumber(item?.amount || 0)}</td>
                                        {item?.status == "pending" && <td  className='text-capitalize'><span class="badge bg-warning" style={{backgroundColor:"orange"}}>{item.status}</span></td>}
                                        {item?.status == "approved" && <td  className='text-capitalize '><span class="badge bg-success" style={{backgroundColor:"green"}}>{item.status}</span></td>}
                                        {item?.status == "rejected" && <td  className='text-capitalize '><span class="badge bg-danger" style={{backgroundColor:"red"}}>{item.status}</span></td>}

                                        <td><span className="badge bg-success" style={{cursor:"pointer"}} onClick={() => openModal(item._id)}>Show</span></td>
                                        <td >
                                            <div className='d-flex gap-3 align-items-center'>
                                                <FaRegCheckCircle className="text-success fs-5" style={{cursor:"pointer"}}
                                                //  onClick={() => handleAccept(item._id)} 
                                                onClick={()=> openApproveModal(item._id)}
                                                 />
                                                <RiCloseCircleLine style={{cursor:"pointer"}}
                                                    className="text-danger fs-4 border-2" 
                                                    onClick={()=> openDisapprove(item._id)}
                                                    //  onClick={() => handleReject(item._id)}
                                                      />
                                            </div>
                                        </td>
                                       
                                    </tr>
                                ))}
                                


                            </tbody>
                        </table>
                        </div>
                        <NewPagination {...paginationProps} />
                    </>
                    : <NotFound cls={styles.not_found}/>}
            </div>
            {isOpen && <RefundModal id={ID} isOpen={isOpen} closeModal={closeModal} />}
            {modalVisible && <ApproveModal title="approve" apiTitle="approve" action={"Approve"} id={IDapprove} show={modalVisible} handleClose={closeApproveModal} getData={getData} />}
            {modalDisapprove && <ApproveModal title="reject" apiTitle="reject" action={"Reject"} id={IDreject} show={modalDisapprove} handleClose={closeDisapproveModal} getData={getData} />}

        </React.Fragment>
    )
}

export default RefundRequest