import React, { useEffect, useState } from 'react'
import styles from './supp.module.css'
import Ticket from './SupportTicket/Ticket';
import TopNavbar from '../../Component/TopMenu/TopNavbar';
import NavbarSection from '../../Component/NavbarSection/NavbarSection';
import ContainerSection from '../../Component/ContainerSection/ContainerSection';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../Apis/BaseUrl';
import axios from 'axios';
import NewPagination from '../../Component/NewPagination/NewPagination';
import Moment from 'react-moment';
import NotFound from '../../Component/NotFound/NotFound';
import formatter from '../../Component/NumberFormatter/formatter';
import moment from 'moment';
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css'

const td1 = [
    {
        h5: 'Total Tickets',
        p: '0'
    },
    {
        h5: 'Recent Ticket Raised',
        p: '0',
    },
    {
        h5: 'Resolved Tickets',
        p: '0'
    },
    {
        h5: 'Pending Tickets',
        p: '0'
    },
    {
        h5: 'Percent Success',
        p: '0'
    }
]

const Support = () => {
    const [tileData, setTileData] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [pageInfo, setPageInfo] = useState({})
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [status, setStatus] = useState('')
    const [loading, setLoading] = useState(false)
    const [value, onChange] = useState('');


    let profileTokenJson = Cookies.get("v&b_token");
    let profileToken = JSON.parse(profileTokenJson);
    // console.log(profileToken.token)
    let token = profileToken.token;
    const getTileData = async () => {

        let register = `${BASE_URL}/ticket/tiles`
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        console.log(res.data.data)
        td1[0].p = res.data.data?.totalTickets
        td1[1].p = moment(res.data.data?.recentTicketRaised?.updatedAt).format("YYYY/MM/DD")
        td1[2].p = res.data.data?.resolvedTickets
        td1[3].p = res.data.data?.pendingTickets
        td1[4].p = res.data.data?.percentSuccess + "%"
        setTileData(res.data.data)
    }

    useEffect(() => {
        getTileData()
    }, [])

    const getData = async () => {
        let dateValue = value ? moment(value).format('YYYY-MM-DD') : " "; // Convert to null if empty string

        let register = `${BASE_URL}/ticket?limit=${limit}&page=${page}&status=${status}&search=${search}&date=${dateValue}`

        // console.log(register)
        let res = await axios.get(register, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        // console.log(res.data.data?.docs)
        setData(res.data.data?.docs)
        setPageInfo({ ...res.data.data, docs: null })
    }

    useEffect(() => {
        getData()
    }, [limit, page, search, status, value])
    const paginationProps = {
        setPage,
        pageInfo, setLimit
    }
    const navigate = useNavigate()

    const closeModal = () => {
        setIsOpen(false)
    }
    const openModal = () => {
        setIsOpen(true)
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>Support</title>
            </Helmet>
            <TopNavbar />
            <NavbarSection />

            {/* -----------------hero-section---------------- */}

            <div className={styles.hero_Section}>
                <ContainerSection data={td1} />
                <div className={styles.filter_section}>
                    <div className={styles.filter}>
                        <p>Filters : </p>
                        <p>Status </p>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="" onChange={(e) => setStatus(e.target.value)} checked={status == ""} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                All
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="open" onChange={(e) => setStatus(e.target.value)} checked={status == "open"} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Open
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                value="closed" onChange={(e) => setStatus(e.target.value)}
                                checked={status == "closed"} />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Closed
                            </label>
                        </div>
                        <div className="form-check">

                            <input className={`${styles.form_check_input}`} type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                value="promo-request" onChange={(e) => setStatus(e.target.value)}
                                checked={status == "promo-request"} />
                            <label className="form-check-label" for="flexRadioDefault2">
                            Request for Promo Code
                            </label>
                        </div>
                    </div>
                </div>

                <div className={styles.product_review_section}>
                    <h6 className='mb-0'>Support</h6>
                    <div className={styles.product_review}>
                        <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} style={{
                            height: "40px",
                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid #d9d9d9", outline: "none"
                        }} placeholder="Search By Ticket ID..." required />
                        <DatePicker className={styles.choose_date}
                          format="y/MM/dd"
                           onChange={onChange} value={value} />
                    </div>
                </div>


                {/* -------------table_section-------------- */}
                {data?.length > 0 ?
                    <div className={styles.main_table}>
                        <table className="table">
                            <thead>
                                <tr>
                                    {/* <th scope="col"><input type="radio" /></th> */}
                                    <th  className='px-5'>Ticket ID</th>
                                    <th> Sending Date</th>
                                    <th>Seller/Customer</th>
                                    <th>Role</th>
                                    <th>Phone</th>
                                    <th>Status</th>
                                    <th>Last reply</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((item, index) => (
                                    <tr>
                                        {/* <th scope="row"><input type="radio" /></th> */}
                                        <td className='px-5'>{item?.ticketId ? "#" + item?.ticketId : "---"}</td>
                                        <td><Moment format="YYYY/MM/DD" utc>{item?.createdAt}</Moment></td>
                                        <td className='text-capitalize'>{item?.createdBy?.name ? item?.createdBy?.name : "---"}</td>
                                        <td  className='text-capitalize'>{item?.createdBy?.role ? item?.createdBy?.role : "---"}</td>
                                        <td>{item?.createdBy?.role == "user" ? item?.createdBy?.phone : item?.primaryContact}</td>
                                        {item?.status == "open" && <td  className='text-capitalize'><span class="badge" style={{backgroundColor:"green"}}>{item.status}</span></td>}
                                        {item?.status == "closed" && <td  className='text-capitalize'><span class="badge bg-danger" >{item.status}</span></td>}
                                        
                                        <td> <Moment format="YYYY/MM/DD" utc>{item?.updatedAt}</Moment></td>
                                        <td>
                                            <div className={styles.image_section}>
                                                {/* <Ticket isOpen={isOpen} closeModal={closeModal} /> */}
                                                <img onClick={() => navigate(`/support/detail/${item?._id}`)} src="/image/eye.png" alt="eye" />
                                                {/* <img src="/image/delete.png" alt="delete" /> */}
                                            </div>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <NewPagination {...paginationProps} />
                    </div>
                    : <NotFound />}
            </div>
        </React.Fragment>
    )
}

export default Support